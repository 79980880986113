.TopNavBarSticky {
  position: sticky;
  top: 0;
  background-color: white;
}
.TopNavBar {
  color: #082c64;
  background-color: white;
}

.is-active {
  background-color: lightgray !important;
}

.NavLinkColor {
  color: #082c64 !important;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  margin: 0px 10px;
}

.NavBrandColor {
  color: #082c64 !important;
  font-size: 2rem;
  font-weight: bolder;
}

.LinksList {
  padding-left: 1rem;
  padding-right: 1rem;
}

.LanguageButton {
  background-color: lightgray;
  color: #082c64 !important;
  border-radius: 0;
  margin: 0px 10px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px 12px;
}
