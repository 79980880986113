.objectifs {
  margin-top: 2rem;
}
.homeContainer {
  background-color: #082c64;
}
.slogan {
  margin: 0;
  padding: 0;
  vertical-align: middle; /*middle centred*/
  display: table-cell; /*acts like a table cell*/
}

.HomeImage {
  width: 100%;
  height: 100%;
}
@media (max-width: 900px) {
  .SloganSection {
    height: 200px;
    margin: 1rem;
  }
  .objectifs {
    margin-top: 1rem;
  }
}

.SloganSection {
  text-align: center;
  margin-top: 4rem;
  width: 100%;
}
