.col__img {
  height: 100%;
  width: 100%;
}
.col__date {
  border-radius: 5px;
  background-color: #082c64;
  color: white;
  text-align: center;
  font-weight: bold;
}
.col__title {
  font-weight: bold;
  font-size: 18px;
}
.link_to {
  text-decoration: none;
  color: black;
  margin-top: 4rem;
}

@media (max-width: 700px) {
  .activity-container {
    border: 2px solid lightgray;
    padding: 1rem;
  }
}
