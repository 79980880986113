.BioContainer {
  background-color: #082c64;
  color: white;
}
.bio {
  font-size: 18px;
}

.ImageProfil {
  width: 100%;
  max-height: 100%;
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 700px) {
  .ImageProfil {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .bio {
    margin: 1rem !important;
  }
  .bioHeader {
    font-size: 2.5rem !important;
  }
}

.Divider {
  border-top: 2px solid white;
  margin: 20px 0px;
}
