* {
  font-family: "Urbanist", sans-serif;
}
.App {
  text-align: center;
}

.SocialIcons {
  font-size: 30px;
  color: #082c64 !important;
}
.NavSocialColor {
  color: #082c64 !important;
  font-weight: bold;
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
}
